<template>
  <!-- Page container-->
  <div class="container">
    <!-- Left Side start -->
    <div class="left">
      <div class="left__insider">
        <!-- Header start -->
        <header class="header">
          <img
            src="../assets/images/touchmedia-logo.png"
            alt="Touch Media Logo"
            class="header__image"
          />
        </header>
        <!-- Header end -->
        <!-- Main start -->
        <main class="main">
          <div class="main__wrapper">
            <h1 class="main__heading">
              Em <br class="hidden" />
              Construção
            </h1>
            <p class="main__paragraph mg-tp-sm">
              Nosso website está em desenvolvimento. Estaremos online em pouco
              tempo. Fique atento!
            </p>
            <p class="main__paragraph">
              Enquanto isso, se precisar de um Website ou Arte para Mídias
              Sociais, entre em contato conosco clicando no botão abaixo:
            </p>
            <a href="mailto:contato@touchmedia.com.br">
              <button class="main__button mg-tp-sm">Entre em contato!</button>
            </a>
          </div>
        </main>
        <!-- Main end -->
        <!-- Footer start -->
        <footer class="footer">
          <ul class="footer__ul">
            <li class="stayintouch">Mantenha contato:</li>
            <li>
              <a href="https://facebook.com/touchmediabr">
                <img
                  class="icon"
                  src="../assets/images/icons/logo-facebook.svg"
                  alt="Facebook"
                />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/designtouchmedia">
                <img
                  class="icon"
                  src="../assets/images/icons/logo-instagram.svg"
                  alt="Instagram"
                />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/touchmediabr">
                <img
                  class="icon"
                  src="../assets/images/icons/logo-linkedin.svg"
                  alt="LinkedIn"
                />
              </a>
            </li>
            <li>
              <a href="https://github.com/touchmediabr">
                <img
                  class="icon"
                  src="../assets/images/icons/logo-github.svg"
                  alt="GitHub"
                />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/touchmediabr">
                <img
                  class="icon"
                  src="../assets/images/icons/logo-twitter.svg"
                  alt="Twitter"
                />
              </a>
            </li>
            <li>
              <a href="mailto:contato@touchmedia.com.br">
                <img
                  class="icon"
                  src="../assets/images/icons/mail.svg"
                  alt="contato@touchmedia.com.br"
                />
              </a>
            </li>
          </ul>
        </footer>
        <!-- Footer end-->
      </div>
    </div>
    <!-- Left side end -->
    <!-- Right Side start -->
    <div class="right"></div>
    <!-- Right Side end -->
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="sass">
@import "../assets/css/main.sass"
</style>
